<template>
  <div class="home-page">
    <section id="section-1" class="py-5 container">
      <div class="row">
        <div class="col-lg-6 order-2 order-lg-1">
          <h1 class="mb-4 t3">
            Promova a transformação<br class="d-none d-lg-block" />
            digital inteligente que seu<br class="d-none d-lg-block" />
            cliente precisa
          </h1>
          <h5 class="mt-2 mb-4">
            Transforme a experiência de seus clientes e colaboradores com<br
              class="d-none d-lg-block"
            />
            soluções digitais adaptativas e fluídas.
          </h5>
          <ButtonComponent copy="Fale com um especialista" type="ice" />
        </div>
        <div class="col-lg-6 text-center mb-lg-0 mb-4 order-1 order-lg-2">
          <img
            class="img-fluid"
            src="../assets/imgs/index_header_1.png"
            alt="Header assets"
          />
        </div>
      </div>
    </section>
    <section id="section-2" class="bg-water py-5 mb-5 position-relative">
      <img
        src="../assets/imgs/polygon_index.png"
        alt="Polygon"
        class="polygon"
      />
      <div class="container text-container">
        <div class="row">
          <div class="col-lg-7">
            <h2 class="t2">
              Acreditamos que as melhores soluções<br
                class="d-none d-lg-block"
              />
              nascem do poder das mentes criativas e das<br
                class="d-none d-lg-block"
              />
              parcerias estratégicas
            </h2>
          </div>
          <div class="col-lg-5">
            <p class="mb-0">
              Nossa equipe de especialistas, juntamente com nossos parceiros
              líderes em tecnologia, estão dedicados a superar desafios e criar
              soluções disruptivas que se adaptam perfeitamente às necessidades
              dos nossos clientes e impulsionam o crescimento dos negócios.
              Explorar como uma abordagem única nos permite entregar resultados
              excepcionais em todas as etapas do ciclo de vida do cliente.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="section-3">
      <div class="container py-5">
        <h2 class="text-center t2 mb-5">Nossos valores</h2>
        <Carousel />
      </div>
    </section>

    <section id="section-4" class="bg-ice py-5">
      <div class="container py-5">
        <div class="text-center">
          <h2 class="mb-4">Transforme sua empresa agora!</h2>
          <p class="mb-4 mt-2">
            Se você deseja detalhes das soluções, uma demonstração da
            plataforma<br class="d-none d-lg-block" />
            ou qualquer outra coisa, converse com a gente que te ajudamos!
          </p>
          <div class="d-flex justify-content-center">
            <ButtonComponent copy="Fale conosco" button="clay" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup>
definePageMeta({
  layout: "default",
});

useHead({
  title: "Like water cs",
  meta: [
    {
      name: "description",
      content:
        "Transforme a experiência de seus clientes e colaboradores com soluções digitais adaptativas e fluídas.",
    },
  ],
});
</script>
<style lang="scss" scoped>
.home-page {
  #section-1 {
    min-height: 60vh;
    h5 {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  #section-2 {
    color: #fff;
    .polygon {
      position: absolute;
      right: 0;
    }

    .text-container {
      position: relative;
      z-index: 3;
    }

    p {
      position: relative;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 200% */
      z-index: 3;
    }

    @media screen and (max-width: 991px) {
      .polygon {
        bottom: 0;
      }
    }
  }

  #section-3 {
  }

  #section-4 {
    h2 {
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px;
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
</style>
